<!--
 * @Author: your name
 * @Date: 2020-10-26 15:28:11
 * @LastEditTime: 2021-02-07 14:19:44
 * @LastEditors: yangliao
 * @Description: 设置
 * @FilePath: /netHallOfficialAccounts/src/views/Setting/index.vue
-->
<template>
  <div class="setting-page">
    <div class="topField">
      <!-- 已认证 -->
      <van-cell-group v-if="dataInfo.isCertified">
        <!-- <van-field v-model="dataInfo.realName" label="姓名" placeholder="请输入姓名" readonly />
        <van-field v-model="dataInfo.idNoStar" label="身份证" placeholder="请输入身份证" readonly /> -->
        <van-field
          v-model="dataInfo.phoneStar"
          label="手机号"
          right-icon="arrow"
          @click="showPhoneSetting"
          readonly
        />
        <van-field
          v-model="dataInfo.email"
          label="邮箱"
          right-icon="arrow"
          @click="showEmailSetting"
          readonly
        />
      </van-cell-group>
      <!-- 未认证 -->
      <van-cell-group v-else>
        <!-- <van-field
          value="待认证"
          label="姓名"
          placeholder="请输入姓名"
          readonly
          right-icon="arrow"
          @click="gotoCertified"
        />
        <van-field
          value="待认证"
          label="身份证"
          placeholder="请输入身份证"
          readonly
          right-icon="arrow"
          @click="gotoCertified"
        /> -->
        <van-field
          v-model="dataInfo.phone"
          label="手机号"
          readonly
          right-icon="arrow"
          @click="gotoCertified"
        />
        <van-field
          v-model="dataInfo.email"
          label="邮箱"
          label-width="60px"
          right-icon="arrow"
          @click="showEmailSetting"
          readonly
        />
      </van-cell-group>
    </div>

    <!-- <div class="centerField">
            <van-cell-group>
                <van-field label="电子发票设置" right-icon="arrow" @click="gotoInvoiceSetting" readonly/>
                <p>设置电子发票的自动生成和发送</p>
            </van-cell-group>
        </div> -->

    <!-- <div class="bottomField">
            <van-cell-group>
                <van-field label="停水复水通知设置" right-icon="arrow" label-width="140px" @click="gotoOtherPage" readonly/>
                <p>{{switchName}}</p>
            </van-cell-group>
        </div> -->

    <!-- 发送到邮箱-弹框 -->
    <van-popup
      v-model="show"
      round
      closeable
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popup-content">
        <div class="title">
          <p>邮箱设置</p>
        </div>
        <div class="content">
          <van-field
            v-model="fieldEmail"
            placeholder="请填写您的邮箱"
            @input="getEamilList"
            clearable
          />
          <div class="list-content" v-if="showEmailList">
            <ul>
              <li v-for="(item, index) in listContent" :key="index" @click="getInputValue(item)">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="btn">
            <van-button type="default" class="cancelBtn" @click="show = false">取消</van-button>
            <van-button
              type="info"
              class="sendBtn"
              @click="sureContent"
              :disabled="fieldEmail && fieldEmail.length <= 0"
              >确定</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 手机号-弹框 -->
    <van-popup
      v-model="showPhone"
      round
      closeable
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popup-content">
        <div class="title">
          <p>手机号设置</p>
        </div>
        <div class="content">
          <van-field
            v-model="fieldPhone"
            placeholder="请填写您的手机号"
            @input="validatorPhone"
            clearable
          />
          <div class="phone-content" v-if="validatorPhoneResult">
            请输入正确手机号
          </div>
          <div class="btn">
            <van-button type="default" class="cancelBtn" @click="showPhone = false"
              >取消</van-button
            >
            <van-button
              type="info"
              class="sendBtn"
              @click="surePhoneContent"
              :disabled="
                !fieldPhone || (fieldPhone && fieldPhone.length <= 0) || validatorPhoneResult
              "
              >确定</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { isEmail } from '@/utils/validator';
import {
  selectCustRealInfoById,
  updateEmail,
  selectDictEnumByCode,
  updatePhone,
} from '../../api/home';

export default {
  name: 'Setting',
  data() {
    return {
      dataInfo: {},
      switchName: '',
      fieldEmail: '',
      fieldPhone: '',
      show: false,
      showPhone: false,
      listContent: [],
      showEmailList: true,
      validatorPhoneResult: false,
    };
  },
  computed: {
    certifyType() {
      return this.$store.state.user.homeInfo.certifyType;
    },
  },
  mounted() {
    this.selectCustRealInfoById();
  },
  methods: {
    validatorEmail(val) {
      return isEmail(val);
    },
    async selectCustRealInfoById() {
      const { status, resultData } = await selectCustRealInfoById();
      if (status === 'complete') {
        // 脱敏
        resultData.phoneStar = resultData.phone.replace(/^(.{3})(?:\d+)(.{2})$/, '\$1****\$2');
        resultData.idNoStar = resultData.idNo.replace(/^(.{3})(?:\d+)(.{2})$/, '\$1****\$2');
        this.dataInfo = resultData;
        this.fieldEmail = resultData.email;
        const { status: noticeStatus, resultData: noticeResultData } = await selectDictEnumByCode(
          'NOTICE_TYPE'
        );
        if (noticeStatus === 'complete') {
          let name = '';
          if (resultData && resultData.notifySwitchVOList.some((item) => item.switchStatus)) {
            resultData &&
              resultData.notifySwitchVOList.map((item) => {
                if (item.switchStatus) {
                  console.log(item.switchStatus);
                  noticeResultData &&
                    noticeResultData.map((list) => {
                      if (item.switchCode === list.code) {
                        console.log(list.name);
                        name = name.concat(list.name + ' ');
                      }
                    });
                }
              });
            this.switchName = name;
          } else {
            this.switchName = '关';
          }
        }
      }
    },
    gotoPhone() {
      store.dispatch('user/savePathUrl', { url: '/Setting' });
      this.$router.push({
        name: 'ChangeCurrentPhone',
        path: '/Setting/changeCurrentPhone',
        query: {
          phone: this.dataInfo.phone,
        },
      });
    },
    gotoOtherPage() {
      this.$router.push({ path: '/Setting/notice' });
    },
    // 邮箱确定按钮事件
    async sureContent() {
      if (this.validatorEmail(this.fieldEmail)) {
        this.dataInfo.email = this.fieldEmail;
        let arg = {
          email: this.dataInfo.email,
        };
        const { status, resultData } = await updateEmail(arg);
        if (status === 'complete') {
          this.selectCustRealInfoById();
          Toast('邮箱设置成功');
          this.show = false;
        }
      } else {
        Toast('请输入正确的邮箱地址！');
      }
    },
    showEmailSetting() {
      this.show = true;
      this.fieldEmail = this.dataInfo.email;
    },
    showPhoneSetting() {
      this.showPhone = true;
      this.validatorPhoneResult = false;
      this.fieldPhone = this.dataInfo.phone;
    },
    validatorPhone(value) {
      let val = value;
      const patrn = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      this.validatorPhoneResult = !patrn.test(val);
    },
    // 邮箱确定按钮事件
    async surePhoneContent() {
      this.dataInfo.phone = this.fieldPhone;
      let arg = {
        custRealId: this.dataInfo.custRealId,
        phone: this.dataInfo.phone,
      };
      const { status, resultData } = await updatePhone(arg);
      if (status === 'complete') {
        this.selectCustRealInfoById();
        Toast('手机号设置成功');
        this.showPhone = false;
      }
    },
    // 获取邮箱列表
    getEamilList(event) {
      this.listContent = [];
      if (event.indexOf('@qq') >= 0) {
        this.listContent.push(`${event}@163.com`, `${event}@126.com`, `${event}@sohu.com`);
      } else if (event.indexOf('@163') >= 0) {
        this.listContent.push(`${event}@qq.com`, `${event}@126.com`, `${event}@sohu.com`);
      } else if (event.indexOf('@126') >= 0) {
        this.listContent.push(`${event}@qq.com`, `${event}@163.com`, `${event}@sohu.com`);
      } else if (event.indexOf('@sohu') >= 0) {
        this.listContent.push(`${event}@qq.com`, `${event}@163.com`, `${event}@126.com`);
      } else if (event.indexOf('@') >= 0) {
        this.listContent = [];
      } else {
        this.listContent.push(
          `${event}@qq.com`,
          `${event}@163.com`,
          `${event}@126.com`,
          `${event}@sohu.com`
        );
      }
      this.showEmailList = true;
    },
    // 点击列表获取当前选中的邮箱
    getInputValue(value) {
      this.fieldEmail = value;
      this.showEmailList = false;
    },
    // 去认证！
    gotoCertified() {
      this.$router.push({ name: 'CertifiedPage', path: '/CertifiedPage' });
    },
    gotoInvoiceSetting() {
      this.$router.push({ name: 'InvoiceSetting', path: '/Invoice/invoiceSetting' });
    },
  },
};
</script>

<style lang="less" scoped>
.setting-page {
  .topField {
    margin-bottom: 10px;
    /deep/.van-field__control {
      text-align: right;
    }
  }
  // .centerField {
  //     margin-bottom: 10px;
  //     p {
  //         margin: 0px;
  //         line-height: 44px;
  //         padding-left: 15px;
  //         color: #5E6268;
  //         font-size: 14px;
  //     }
  // }
  // .bottomField {
  //     margin-bottom: 10px;
  //     p {
  //         margin: 0px;
  //         line-height: 44px;
  //         padding-left: 15px;
  //         color: #5E6268;
  //         font-size: 14px;
  //     }
  // }

  /deep/.van-cell {
    color: #333333 !important;
    font-size: 16px;
  }
  /deep/.van-field__label {
    color: #333333 !important;
    font-size: 16px;
  }
  .popup-content {
    .title {
      border-bottom: 1px solid #eeeeee;
      padding-top: 12px;
      padding-bottom: 12px;
      p {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #333333;
        text-align: center;
        line-height: 26px;
        margin: 0px;
      }
    }
    .content {
      /deep/.van-field__control {
        height: 45px;
        background-color: #f9f8f8;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        letter-spacing: 0;
        text-indent: 1em;
      }
      /deep/ .van-cell::after {
        border-radius: 0px solid #ffffff;
      }
      .list-content {
        ul {
          padding-left: 25px;
          padding-right: 25px;
          li {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
      .btn {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .cancelBtn {
          // width: 150px;
          height: 44px;
          border-radius: 4px;
          background: #f6f6f6;
          color: #4285f4;
        }
        .sendBtn {
          // width: 150px;
          height: 44px;
          border-radius: 4px;
        }
        /deep/.van-button {
          width: calc(100% / 2 - 25px);
        }
      }
    }
  }
}

.phone-content {
  font-size: 12px;
  color: rgb(240, 13, 13);
  padding-left: 6%;
}
</style>
